export const users = [
  {
    admin: ['charles.thibaut', 'gaelle.renault', 'matias.huwaert'],
    coordi: [
      { id: 1, nom: 'charles.thibaut' },
      { id: 1, nom: 'matias.huwaert' },
      { id: 7, nom: 'matias.huwaert' },
      { id: 4, nom: '' },
      { id: 5, nom: '' },
      { id: 6, nom: '' },
      { id: 7, nom: '' },
      { id: 8, nom: '' },
      { id: 9, nom: '' },
      { id: 10, nom: '' },
      { id: 11, nom: '' },
      { id: 12, nom: '' },
      { id: 13, nom: '' },
      { id: 14, nom: '' },
      { id: 15, nom: '' },
      { id: 16, nom: '' },
    ],
  },
]
