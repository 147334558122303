import React from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Flex, Popconfirm, Tooltip } from 'antd'
import { useDrag, useDrop, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const ItemType = 'LIST_ITEM'

const DraglistItem = ({
  item,
  index,
  moveItem,
  findItem,
  sites,
  setChildrenDrawer,
  setWishlist,
  wishlist,
  childrenDrawer,
  editSite,
  setEditSite,
  setIsInitialMount,
}) => {
  const originalIndex = findItem(index).index

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemType,
      item: { index, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (dropResult, monitor) => {
        const { index: droppedIndex } = monitor.getItem()
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveItem(droppedIndex, originalIndex)
        }
      },
    }),
    [index, originalIndex, moveItem]
  )

  const [, drop] = useDrop(
    () => ({
      accept: ItemType,
      hover: ({ index: draggedIndex }) => {
        if (draggedIndex !== index) {
          moveItem(draggedIndex, index)
        }
      },
      drop: ({ index: draggedIndex }) => {
        if (draggedIndex !== index) {
          moveItem(draggedIndex, index, true)
        }
      },
    }),
    [moveItem]
  )

  const getSiteName = (siteId) => {
    for (let site of sites) {
      if (!site.multisite) {
        if (site.id === siteId) {
          return site.nom
        }
      } else {
        const siteIndex = site.id.indexOf(siteId)
        if (siteIndex !== -1) {
          return site.nom[siteIndex]
        }
      }
    }
    return 'Nom non trouvé'
  }

  const siteName = getSiteName(item.site)
  const deleteWish = async (siteId, prof) => {
    try {
      const response = await fetch('http://localhost:3001/wishlist/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ siteId, prof }),
      })

      const result = await response.json()

      if (response.ok) {
        // Suppression réussie : mettre à jour l'état local
        setIsInitialMount(1)
        setWishlist((prevWishlist) =>
          prevWishlist.filter(
            (wish) => wish.site !== siteId || wish.prof !== prof
          )
        )
        console.log('Wish deleted successfully:', result.message)
      } else {
        console.error('Failed to delete wish:', result.error)
      }
    } catch (error) {
      console.error('Error deleting wish:', error)
    }
  }
  return (
    <li
      ref={(node) => drag(drop(node))}
      style={{
        opacity: isDragging ? 0.5 : 1,
        marginTop: 10,
        marginLeft: 10,
        backgroundColor: 'white',
        cursor: 'grab',
      }}
    >
      <Flex justify="space-between" align="center">
        <span style={{ fontWeight: '600' }}>{siteName}</span>
        <Flex gap={10}>
          <Tooltip title="Editer mon souhait" placement="left">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setChildrenDrawer(true)
                setEditSite(item)
              }}
            />
          </Tooltip>
          <Tooltip title="Retirer ce site de mes souhaits" placement="right">
            <Popconfirm
              title="Supprimer le souhait"
              description="Es-tu sûr de vouloir supprimer ce souhait ?"
              onConfirm={() => deleteWish(item.site, item.prof)}
              okText="Oui"
              cancelText="Non"
            >
              <Button icon={<DeleteOutlined />} danger />
            </Popconfirm>
          </Tooltip>
        </Flex>
      </Flex>
    </li>
  )
}

const Draglist = ({
  liste,
  setListe,
  sites,
  setChildrenDrawer,
  childrenDrawer,
  editSite,
  setEditSite,
  setIsInitialMount,
  setWishlist,
}) => {
  const moveItem = (fromIndex, toIndex, finalize = false) => {
    if (fromIndex === toIndex) return

    const updatedList = [...liste]
    const [movedItem] = updatedList.splice(fromIndex, 1)
    updatedList.splice(toIndex, 0, movedItem)

    if (finalize) {
      // Mise à jour des priorités en s'assurant de ne pas ajouter de doublons
      const updatedListWithPriority = updatedList.map((item, index) => {
        const { priority, ...rest } = item // Suppression de l'ancienne priorité
        return {
          ...rest,
          priority: index + 1, // Ajout de la nouvelle priorité
        }
      })
      setListe(updatedListWithPriority)
    }
  }

  const findItem = (index) => ({
    item: liste[index],
    index,
  })

  return (
    <DndProvider backend={HTML5Backend}>
      <ol style={{ padding: 0 }}>
        {liste.map((item, index) => (
          <DraglistItem
            key={`${item.site}-${index}`}
            index={index}
            item={item}
            moveItem={moveItem}
            findItem={findItem}
            setWishlist={setWishlist}
            sites={sites}
            setChildrenDrawer={setChildrenDrawer}
            childrenDrawer={childrenDrawer}
            editSite={editSite}
            setEditSite={setEditSite}
            setIsInitialMount={setIsInitialMount}
          />
        ))}
      </ol>
    </DndProvider>
  )
}

export default Draglist
