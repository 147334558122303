export const sites = [
  {
    multisite: false,
    lieu: 'wal',
    nom: "L'Interlude",
    adresse: ['Place abbé Renard', '6', '1420', "Braine-l'Alleud"],
    contact: ['Charles Thibaut', 'charles.thibaut@asihs.org', '0497615638'],
    telephone: ['0490097281'],
    cx: '1304.69',
    cy: '135.52',
    horaires: {
      lundi: [
        {
          debut: 8.5,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '8:30 - 9:20',
        },
        {
          debut: 9.33,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '9:20 - 10:10',
        },
        {
          debut: 10.16,
          duree: 0.33,
          type: 'Pause',
          color: '#011627',
          horaire: '10:10 - 10:30',
        },
        {
          debut: 10.5,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '10:30 - 11:20',
        },
        {
          debut: 11.33,
          duree: 0.83,
          type: 'TA',
          color: '#2EC4B6',
          horaire: '11:20 - 12:10',
        },
        {
          debut: 12.16,
          duree: 1,
          type: 'Pause',
          color: '#011627',
          horaire: '12:10 - 13:10',
        },
        {
          debut: 13.16,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '13:10 - 14:00',
        },
        {
          debut: 14,
          duree: 0.83,
          type: "Bol d'air",
          color: '#FF8A5B',
          horaire: '14:00 - 14:50',
        },
      ],
      mardi: [
        {
          debut: 8.5,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '8:30 - 9:20',
        },
        {
          debut: 9.33,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '9:20 - 10:10',
        },
        {
          debut: 10.16,
          duree: 0.33,
          type: 'Pause',
          color: '#011627',
          horaire: '10:10 - 10:30',
        },
        {
          debut: 10.5,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '10:30 - 11:20',
        },
        {
          debut: 11.33,
          duree: 0.83,
          type: 'TA',
          color: '#2EC4B6',
          horaire: '11:20 - 12:10',
        },
        {
          debut: 12.16,
          duree: 1,
          type: 'Pause',
          color: '#011627',
          horaire: '12:10 - 13:10',
        },
        {
          debut: 13.16,
          duree: 0.83,
          type: 'Conseil des jeunes',
          color: '#D0D1AC',
          horaire: '13:10 - 14:00',
        },
        {
          debut: 14,
          duree: 0.83,
          type: 'Titulariat',
          color: '#B8B8FF',
          horaire: '14:00 - 14:50',
        },
      ],
      mercredi: [
        {
          debut: 8.5,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '8:30 - 9:20',
        },
        {
          debut: 9.33,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '9:20 - 10:10',
        },
        {
          debut: 10.16,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '10:10 - 11:00',
        },
        {
          debut: 11,
          duree: 0.33,
          type: 'Pause',
          color: '#011627',
          horaire: '11:00 - 11:20',
        },
        {
          debut: 11.33,
          duree: 1.66,
          type: 'Atelier projet',
          color: '#726DA8',
          horaire: '11:20 - 13:00',
        },
      ],
      jeudi: [
        {
          debut: 8.5,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '8:30 - 9:20',
        },
        {
          debut: 9.33,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '9:20 - 10:10',
        },
        {
          debut: 10.16,
          duree: 0.33,
          type: 'Pause',
          color: '#011627',
          horaire: '10:10 - 10:30',
        },
        {
          debut: 10.5,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '10:30 - 11:20',
        },
        {
          debut: 11.33,
          duree: 0.83,
          type: 'TA',
          color: '#2EC4B6',
          horaire: '11:20 - 12:10',
        },
        {
          debut: 12.16,
          duree: 1,
          type: 'Pause',
          color: '#011627',
          horaire: '12:10 - 13:10',
        },
        {
          debut: 13.16,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '13:10 - 14:00',
        },
        {
          debut: 14,
          duree: 0.83,
          type: 'Atelier lecture',
          color: '#679436',
          horaire: '14:00 - 14:50',
        },
      ],
      vendredi: [
        {
          debut: 8.5,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '8:30 - 9:20',
        },
        {
          debut: 9.33,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '9:20 - 10:10',
        },
        {
          debut: 10.16,
          duree: 0.33,
          type: 'Pause',
          color: '#011627',
          horaire: '10:10 - 10:30',
        },
        {
          debut: 10.5,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '10:30 - 11:20',
        },
        {
          debut: 11.33,
          duree: 0.83,
          type: 'TA',
          color: '#2EC4B6',
          horaire: '11:20 - 12:10',
        },
        {
          debut: 12.16,
          duree: 1,
          type: 'Pause',
          color: '#011627',
          horaire: '12:10 - 13:10',
        },
        {
          debut: 13.16,
          duree: 0.83,
          type: 'Auto-évaluation',
          color: '#FFD8BE',

          horaire: '13:10 - 14:00',
        },
        {
          debut: 14,
          duree: 0.83,
          type: 'Cours',
          color: '#20A4F3',
          horaire: '14:00 - 14:50',
        },
      ],
    },

    activites: [
      {
        titre: 'Conseil des jeunes',
        contenu: (
          <div>
            <p>
              Le conseil des jeunes est moment démocratique durant lequel les
              jeunes discutent de la vie à L'Interlude.
            </p>
            <p>
              Des idées de projet peuvent être proposées, des remerciements, des
              félicitations, des difficultés ou des suggestions peuvent y être
              déposées.
            </p>
          </div>
        ),
      },
      {
        titre: 'Atelier lecture',
        contenu: (
          <div>
            <p>
              L'atelier lecture est un moment de 50 minutes qui à lieu le jeudi
              en fin de journée.
            </p>
            <p>
              Durant ce moment, les élèves qui souhaitent lire un livre
              individuellement peuvent le faire.
            </p>
            <p>
              Les enseignants proposent également une lecture à voix haute, soit
              par un ou une enseignante, soit par un ou une jeune.
            </p>
            <p>
              Il arrive que l'enseignant propose une lecture sur un thème précis
              dans le but d'en discuter ou d'en débattre par la suite.
            </p>
          </div>
        ),
      },
    ],
    lien: 'https://drive.google.com/file/d/1H5MgGWcy7qVZtjLd4HqNGow1QQUn83KH/view',
    id: 1,
    description: (
      <>
        <p>
          L'Interlude est site qui accueille une dizaine de jeunes du
          secondaire.
        </p>
        <p>
          Nous accueillons essentiellement des jeunes qui sont en décrochage
          scolaire.
        </p>
        <p>
          Ces jeunes bénéficient de maximum 16 semaines de prises en charge:
        </p>
        <ul>
          <li>
            Un premier bloc de 8 semaines durant lequel le jeune est chez nous à
            temps plein
          </li>
          <li>
            Un dernier bloc de 8 semaines durant lequel le jeune retourne
            progressivement dans son école d'origine
          </li>
        </ul>
      </>
    ),
    accueil: 10,
  },

  {
    multisite: true,
    lieu: 'bxl',
    nom: ["L'Entredeux", "L'Entreliens", 'Saint-Luc', 'C.Th.A'],
    adresse: [
      ["rue de l'entredeux", '7a', '1200', 'Woluwé-Saint-Lambert'],
      ["rue de l'Entreliens", '7a', '1200', 'Woluwé-Saint-Lambert'],
      ['rue de Saint-Luc', '12', '1200', 'Woluwé-Saint-Lambert'],
      ['rue du C.Th.A', '25', '1200', 'Woluwé-Saint-Lambert'],
    ],
    contact: [
      [
        'Christine vanBrusselt',
        'christine.vanbrusselt@asihs.org',
        '0123456789',
      ],
      [
        'Christine vanBrusselt',
        'christine.vanbrusselt@asihs.org',
        '0123456789',
      ],
      ['contact Saint-Luc', 'contact.saintluc@asihs.org', '0123456789'],
      ['contact C.Th.A', 'contact.ctha@asihs.org', '0123456789'],
    ],
    telephone: [['0123456789'], ['1122334455'], [''], ['9876543210']],
    cx: '1677.13',
    cy: '843.72',
    horaires: [
      [
        {
          lundi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          mardi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          mercredi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
          ],
          jeudi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          vendredi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
        },
      ],
      [
        {
          lundi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          mardi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          mercredi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
          ],
          jeudi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          vendredi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
        },
      ],
      [
        {
          lundi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          mardi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          mercredi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
          ],
          jeudi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          vendredi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
        },
      ],
      [
        {
          lundi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          mardi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          mercredi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
          ],
          jeudi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
          vendredi: [
            {
              debut: 8.16,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '8:10 - 9:00',
            },
            {
              debut: 9,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '9:00 - 9:50',
            },
            {
              debut: 9.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '09:50 - 10:40',
            },
            {
              debut: 10.66,
              duree: 0.33,
              type: 'Pause',
              color: '#011627',
              horaire: '10:40 - 11:00',
            },
            {
              debut: 11,
              duree: 0.83,
              type: 'Cours',
              color: '#20A4F3',
              horaire: '11:00 - 11:50',
            },
            {
              debut: 11.83,
              duree: 0.83,
              type: 'Cours',
              color: '#011627',
              horaire: '11:50 - 12:40',
            },
            {
              debut: 12.66,
              duree: 0.83,
              type: 'Pause',
              color: '#011627',
              horaire: '12:40 - 13:30',
            },
            {
              debut: 13.5,
              duree: 0.83,
              type: 'Activité 1',
              color: '#FF8A5B',
              horaire: '13:30 - 14:20',
            },
            {
              debut: 14.33,
              duree: 0.83,
              type: 'Activité 2',
              color: '#2EC4B6',
              horaire: '14:20 - 15:10',
            },
          ],
        },
      ],
    ],
    activites: [
      [
        {
          titre: "activité 1 de l'Entredeux",
          contenu: (
            <div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                temporibus nostrum fugiat voluptates sapiente mollitia ea
                inventore. Veniam error similique, nostrum sed eius nam, magni
                ipsum qui, provident quo quidem? Laboriosam fugiat distinctio
                rerum atque totam unde eaque minus deleniti, eius vel temporibus
                inventore quo vero quasi? Modi qui, eos rerum sint consequuntur
                voluptates odio officia corporis iste neque recusandae!
              </p>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum
                minus eos odio error hic similique sapiente quis beatae
                accusantium, ex reprehenderit tenetur. Vitae praesentium
                deserunt eligendi ut, voluptatem ad molestiae!
              </p>
            </div>
          ),
        },
        {
          titre: "Activité 2 de l'Entredeux",
          contenu: (
            <div>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Culpa
                ea necessitatibus id repellendus, quidem, similique ut cumque
                fugit modi minima molestiae aspernatur rerum labore consectetur
                maiores, omnis nemo! Animi, voluptates?
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Distinctio modi temporibus eos hic iusto quia mollitia dolores,
                voluptatibus nemo sunt aspernatur dignissimos quasi porro et
                obcaecati nobis fugit dolore quidem.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Cumque, itaque. A consectetur amet harum voluptate, eius,
                assumenda impedit minima aut eum consequatur aspernatur veniam
                natus, repudiandae architecto cum quod. Rem! Nulla et animi
                excepturi expedita a quasi, dolorem facere velit cumque rem!
                Harum iure quidem praesentium, ipsum, quibusdam iusto ullam
                nesciunt inventore totam vitae, porro reiciendis voluptatum
                magnam odit illo.
              </p>
            </div>
          ),
        },
      ],
      [
        {
          titre: "activité 1 de l'Entreliens",
          contenu: (
            <div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat
                voluptatem iusto adipisci voluptates rerum nobis veritatis
                doloribus alias animi hic? Corrupti placeat cumque veritatis
                pariatur aliquam consectetur sapiente consequuntur iure?
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint
                vero ipsum magnam sed aut architecto rem, velit facere adipisci
                accusamus aliquid dignissimos exercitationem ut consequuntur
                perferendis illo cupiditate ad autem.
              </p>
            </div>
          ),
        },
      ],
      [
        {
          titre: 'activité 1 de Saint-Luc',
          contenu: (
            <div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                temporibus nostrum fugiat voluptates sapiente mollitia ea
                inventore. Veniam error similique, nostrum sed eius nam, magni
                ipsum qui, provident quo quidem? Laboriosam fugiat distinctio
                rerum atque totam unde eaque minus deleniti, eius vel temporibus
                inventore quo vero quasi? Modi qui, eos rerum sint consequuntur
                voluptates odio officia corporis iste neque recusandae!
              </p>
            </div>
          ),
        },
        {
          titre: 'activité 2 de Saint-Luc',
          contenu: (
            <div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                temporibus nostrum fugiat voluptates sapiente mollitia ea
                inventore. Veniam error similique, nostrum sed eius nam, magni
                ipsum qui, provident quo quidem? Laboriosam fugiat distinctio
                rerum atque totam unde eaque minus deleniti, eius vel temporibus
                inventore quo vero quasi? Modi qui, eos rerum sint consequuntur
                voluptates odio officia corporis iste neque recusandae!
              </p>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum
                minus eos odio error hic similique sapiente quis beatae
                accusantium, ex reprehenderit tenetur. Vitae praesentium
                deserunt eligendi ut, voluptatem ad molestiae!
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
                pariatur perferendis numquam, recusandae quidem optio
                dignissimos autem itaque sapiente quia deserunt eius ipsum sed
                quis tenetur distinctio porro inventore officia?
              </p>
            </div>
          ),
        },
        {
          titre: 'activité 3 de Saint-Luc',
          contenu: (
            <div>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum
                minus eos odio error hic similique sapiente quis beatae
                accusantium, ex reprehenderit tenetur. Vitae praesentium
                deserunt eligendi ut, voluptatem ad molestiae!
              </p>
            </div>
          ),
        },
      ],
      [
        {
          titre: 'activité 1 du C.Th.A',
          contenu: (
            <div>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum
                minus eos odio error hic similique sapiente quis beatae
                accusantium, ex reprehenderit tenetur. Vitae praesentium
                deserunt eligendi ut, voluptatem ad molestiae!
              </p>
            </div>
          ),
        },
      ],
    ],
    lien: [
      'https://drive.google.com/file/d/1CveeObjr2G9-EEMdBrlTx2cgf_R9zKls/view',
      'https://drive.google.com/file/d/13hi_NUyAc27FlYCKbUtRI4Q9VIAZ3AkV/view',
      'https://drive.google.com/file/d/1fAuqANE4wANBsR8l4ybY4KAEGo8bMBPQ/view',
      'https://drive.google.com/file/d/1EhL51NV1bSbs_sN1u9sLmy8NKD3Q9zfh/view',
    ],
    id: [5, 6, 7, 8],
    description: [<p></p>, <p></p>, <p></p>, <p></p>],
    accueil: [10, 10, 10, 10],
  },
]
