import './App.css'
import gsap from 'gsap'
import { useEffect, useRef, useState } from 'react'
import { sites } from './datas/sites.js'
import { users } from './datas/users.js'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import 'ckeditor5/ckeditor5.css'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Underline,
  Strikethrough,
  Highlight,
  Alignment,
  Link,
  Font,
} from 'ckeditor5'

import {
  Button,
  Divider,
  Flex,
  Modal,
  Popover,
  Space,
  Tooltip,
  Typography,
  Input,
  FloatButton,
  Segmented,
  Drawer,
  Radio,
  DatePicker,
  ConfigProvider,
  Table,
  Popconfirm,
} from 'antd'
import {
  MailOutlined,
  PhoneOutlined,
  LinkOutlined,
  MenuOutlined,
  SaveOutlined,
  CloseOutlined,
  GoogleOutlined,
  FundViewOutlined,
  EyeOutlined,
  FilePdfOutlined,
} from '@ant-design/icons'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import Draglist from './components/Draglist.jsx'
import locale from 'antd/locale/fr_BE.js'
import dayjs from 'dayjs'
import 'dayjs/locale/fr.js'
import { useQuill } from 'react-quilljs'
import { Cookies, useCookies } from 'react-cookie'
import 'quill/dist/quill.snow.css' // Add css for snow theme

import axios from 'axios'
import html2pdf from 'html2pdf.js'
dayjs.locale('fr')
const apiUrl = process.env.REACT_APP_API_URL

const { Title } = Typography
const { TextArea } = Input
function App() {
  const couleurPrimaire = '#7C898B'
  const couleurSecondaire = '#636564'
  const couleurTertiaire = '#322214'
  const [mapOpen, setMapOpen] = useState('bxl')
  const [OGSite, setOGSite] = useState(null)
  const [drawerSite, setDrawerSite] = useState(null)
  const [siteKey, setSiteKey] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [contentPages, setContentPages] = useState([])
  const [affichageMap, setAffichageMap] = useState(true)
  const [siteId, setSiteId] = useState(null)
  const bxlRef = useRef(null)
  const walRef = useRef(null)
  const pourquoiRef = useRef(null)
  const attentesRef = useRef(null)
  const pourquoiRefDrawer = useRef(null)
  const attentesRefDrawer = useRef(null)
  const [pourquoi, setPourquoi] = useState(null)
  const [attentes, setAttentes] = useState(null)
  const [wishlist, setWishlist] = useState([])
  const [siteWishlist, setSiteWishlist] = useState([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [isInitialMount, setIsInitialMount] = useState(0)
  const [childrenDrawer, setChildrenDrawer] = useState(false)
  const [editSite, setEditSite] = useState(null)
  const [segmentedIndex, setSegmentedIndex] = useState(null)
  const [openStatsDrawer, setOpenStatsDrawer] = useState(false)
  const [statsDatas, setStatsDatas] = useState([])
  const [retourModal, setRetourModal] = useState(null)
  const [openRetourModal, setOpenRetourModal] = useState(null)
  const [statsColumns, setStatsColumns] = useState([])
  const [syntheseDatas, setSyntheseDatas] = useState(null)
  const { quill, quillRef } = useQuill()
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'prof'])
  const bxlMaxPoint =
    '1.28 1072.88 235.01 1015.02 273.04 767.4 205.26 658.63 364.28 478.78 325.6 350.51 696.54 169.33 1006.32 238.76 1194.93 0.72 1481.73 255.29 1400.93 624.91 1734.15 839.47 1833.16 1242.48 1588.85 1374.89 1865.89 1583.67 1069.13 1980.72 592.07 1832.28 455.53 1352.74 1.28 1072.88'
  const walMaxPoint =
    '0.69 102.56 929.99 164.58 1496.17 36.53 1982.02 119.66 2381.46 0.52 2782.59 126.56 3096.69 722.09 2691.75 994.71 2472.49 1499.01 2553.52 1918.14 2210.41 1975.16 1596.21 1479 1572.2 1002.85 1253.1 1317.95 944 1298.94 933.99 874.8 100.72 426.66 0.69 102.56'
  const bxlMinPoint =
    '428.48 1517.32 452.09 1511.48 455.93 1486.46 449.08 1475.48 465.15 1457.31 461.24 1444.35 498.71 1426.05 530 1433.07 549.05 1409.02 578.02 1434.74 569.86 1472.07 603.52 1493.74 613.52 1534.45 588.84 1547.83 616.82 1568.92 536.34 1609.02 488.15 1594.03 474.36 1545.59 428.48 1517.32'
  const walMinPoint =
    '2288.84 923.06 2382.96 929.34 2440.31 916.37 2489.52 924.79 2529.97 912.73 2570.6 925.49 2602.41 985.81 2561.4 1013.42 2539.19 1064.5 2547.4 1106.95 2512.65 1112.72 2450.44 1062.47 2448.01 1014.25 2415.69 1046.16 2384.38 1044.23 2383.37 1001.28 2298.97 955.89 2288.84 923.06'
  const listeSites = [
    [
      sites
        .filter((site) => site.lieu === 'wal')
        .flatMap((site) => {
          if (site.multisite) {
            return site.nom.map((nom, index) => nom)
          } else {
            return site.nom
          }
        }),
    ],
    [
      sites
        .filter((site) => site.lieu === 'bxl')
        .flatMap((site) => {
          if (site.multisite) {
            // Si c'est un multisite, on mappe les noms et ids
            return site.nom.map((nom, index) => ({
              title: nom,
            }))
          } else {
            // Si ce n'est pas un multisite, on retourne un seul objet
            return {
              title: site.nom,
            }
          }
        }),
    ],
  ]

  const expand = () => {
    setMapOpen(mapOpen === 'wal' ? 'bxl' : 'wal')
    const bxl = bxlRef.current
    const wal = walRef.current
    const bxlPoints = mapOpen === 'wal' ? bxlMaxPoint : bxlMinPoint
    const walPoints = mapOpen === 'bxl' ? walMaxPoint : walMinPoint
    const pointsFadeIn = mapOpen === 'wal' ? '.bxlPoint' : '.walPoint'
    const pointsFadeOut = mapOpen === 'wal' ? '.walPoint' : '.bxlPoint'
    if (bxl && wal) {
      gsap.to(bxl, {
        duration: 0.3,
        attr: { points: bxlPoints },
        ease: 'power3.inOut',
      })
      gsap.to(wal, {
        duration: 0.3,
        attr: { points: walPoints },
        ease: 'power3.inOut',
      })
      gsap
        .timeline()
        .to(
          pointsFadeIn,
          {
            duration: 0.2,
            opacity: 1,
            ease: 'power3.inOut',
            stagger: {
              each: 0.05,
              from: 'start',
            },
          },
          0
        )
        .to(
          pointsFadeOut,
          {
            duration: 0.2,
            opacity: 0,
          },
          0
        )
    }
  }

  const getSite = (site, index) => {
    const item = {
      noms: site.nom,
      multisite: true,
      lieu: site.lieu,
      nom: site.nom[index],
      adresse: site.adresse[index],
      contact: site.contact[index],
      telephone: site.telephone[index],
      horaires: site.horaires[index][0],
      activites: site.activites[index],
      lien: site.lien[index],
      id: site.id[index],
      ids: site.id,
      description: site.description[index],
    }

    return item
  }
  const handleMouseOver = (site, key = 0, index) => {
    setSiteKey(index)
    setOGSite(site)
    if (site.multisite) {
      setDrawerSite(getSite(site, key))
      setSiteId(site.id[key])
    } else {
      setDrawerSite(site)
      setSiteId(site.id)
    }
  }

  function formatPhoneNumber(phoneNumber) {
    const number = parsePhoneNumberFromString(phoneNumber, 'BE')
    return number ? number.formatInternational() : phoneNumber
  }
  const openingModal = (id) => {
    setSiteId(id)
    setModalOpen(true)
  }
  const handleModalOk = async (id) => {
    setLoadingModal(true)

    const pourquoi = pourquoiRef.current.resizableTextArea.textArea.value
    const attentes = attentesRef.current.resizableTextArea.textArea.value

    // Construire le nouvel objet wish avec la priorité calculée
    const wish = {
      id: null,
      prof: cookies['prof'],
      site: siteId,
      pourquoi: pourquoi === '' ? null : pourquoi,
      attentes: attentes === '' ? null : attentes,
      date: null,
      retour: null,
    }

    setWishlist([...wishlist, wish])
    //setSiteWishlist([...siteWishlist, siteId])

    setModalOpen(false)
    setLoadingModal(false)
  }
  const handleModalCancel = () => {
    setModalOpen(false)
    setLoadingModal(false)
  }
  const fetchWishes = async () => {
    if (cookies['prof']) {
      try {
        // Envoyer une requête POST au serveur avec la valeur de `prof`
        const response = await fetch(`${apiUrl}/mywishes`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ prof: cookies['prof'] }),
        })

        // Vérifier si la réponse est correcte
        if (!response.ok) {
          throw new Error(`Failed to fetch wishes: ${response.statusText}`)
        }

        // Extraire et retourner les données JSON de la réponse
        const data = await response.json()

        setWishlist(data)
        const ids = []
        data.map((site) => {
          ids.push(site.site)
        })
        setSiteWishlist(ids)
      } catch (error) {
        console.error('Error fetching wishes:', error)
        return null
      }
    }
  }

  const onCloseDrawer = () => {
    setOpenDrawer(false)
  }
  const getSiteName = (siteId) => {
    for (let site of sites) {
      if (!site.multisite) {
        if (site.id === siteId) {
          return site.nom
        }
      } else {
        const siteIndex = site.id.indexOf(siteId)
        if (siteIndex !== -1) {
          return site.nom[siteIndex]
        }
      }
    }
    return 'Nom non trouvé'
  }
  useEffect(() => {
    expand()
  }, [affichageMap])

  const updateWishlist = () => {
    wishlist.map(async (wish) => {
      const endpoint = wish.id
        ? `${apiUrl}/wishlist/update`
        : `${apiUrl}/wishlist/add`

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(wish),
      })
      if (response.ok) {
        const data = await response.json()
        //console.log('Sync successful:', data)
        // Si c'était une insertion, tu peux mettre à jour l'id de l'élément dans la wishlist
        if (!wish.id && data.itemId && data.priority) {
          setIsInitialMount(1)
          setWishlist((prevWishlist) =>
            prevWishlist.map((item) =>
              item === wish
                ? { ...item, id: data.itemId, priority: data.priority }
                : item
            )
          )
        }
      } else {
        console.error('Failed to sync wish with DB:', response.statusText)
      }
    })
  }
  useEffect(() => {
    if (isInitialMount < 2) {
      setIsInitialMount(isInitialMount + 1)
    } else {
      updateWishlist()
    }
    const siteIds = []
    wishlist.map((wish) => {
      siteIds.push(wish.site)
    })
    setSiteWishlist(siteIds)
  }, [wishlist])

  const showChildrenDrawer = () => {
    setChildrenDrawer(true)
  }

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false)
  }
  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(editSite ? editSite.retour : null)
      quill.on('text-change', (delta, oldDelta, source) => {
        const retour = quill.root.innerHTML // Get innerHTML using quill
        setEditSite((prevEditSite) => ({
          ...prevEditSite, // Copie les autres propriétés existantes
          retour: retour, // Met à jour la propriété `pourquoi`
        }))
      })
    }
  }, [quill, childrenDrawer])

  const saveEditSite = () => {
    // Utiliser map pour créer un nouveau tableau avec l'objet mis à jour
    const updatedWishlist = wishlist.map((item) => {
      // Si l'objet a le même site que editSite, remplace-le par editSite
      if (item.site === editSite.site) {
        return { ...editSite }
      }
      // Sinon, garde l'objet inchangé
      return item
    })

    // Mettre à jour l'état wishlist avec le tableau mis à jour
    setWishlist(updatedWishlist)
    setChildrenDrawer(false)
  }

  const logOut = function () {
    removeCookie('prof')
    removeCookie('token')
    removeCookie('site')
    removeCookie('admin')
    const currentUrl = window.location.href

    // Si l'URL contient des paramètres après le "#", les supprimer
    const cleanUrl = currentUrl.split('#')[0] // Garder seulement la partie avant le '#'

    // Remplace l'URL actuelle dans la barre d'adresse sans recharger la page
    window.history.replaceState(null, null, cleanUrl)
  }
  const handleGoogleLogin = () => {
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=token&scope=email%20profile`
    window.location.href = googleAuthUrl
  }
  useEffect(() => {
    // Récupération du token depuis l'URL après redirection de Google
    const getAccessTokenFromUrl = () => {
      const params = new URLSearchParams(window.location.hash.substring(1))
      return params.get('access_token')
    }

    const fetchUserData = async (accessToken) => {
      try {
        const response = await axios.get(
          'https://people.googleapis.com/v1/people/me?personFields=emailAddresses,names',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )

        const userData = response.data
        //console.log('User Data:', userData)

        // Filtrer les emails qui se terminent par @asihs.org ou @ecole-ecale.be
        const filteredEmails = userData.emailAddresses.filter(
          (email) =>
            email.value.endsWith('@asihs.org') ||
            email.value.endsWith('@ecole-ecale.be')
        )

        // S'il y a au moins une adresse email valide, on enregistre le token et l'email dans les cookies
        if (filteredEmails.length > 0) {
          const email = filteredEmails[0].value
          const prof = email.split('@')[0] // Récupérer la partie avant le @

          // Vérifier si 'prof' est un admin ou un coordi
          const isAdmin = users[0].admin.includes(prof)

          if (isAdmin) {
            // Si c'est un admin, on définit le cookie admin à 2
            setCookie('admin', 2, { path: '/' })
            setCookie('site', null, { path: '/' })
          } else {
            // Si ce n'est pas un admin, on vérifie les coordonnées
            const coordiSites = users[0].coordi
              .filter((c) => c.nom === prof)
              .map((c) => c.id)

            if (coordiSites.length > 0) {
              // Si le prof est coordi, admin = 1 et site = tableau d'IDs
              setCookie('admin', 1, { path: '/' })
              setCookie('site', JSON.stringify(coordiSites), { path: '/' })
            } else {
              // Si ni admin ni coordi, on supprime les cookies admin et site
              setCookie('admin', null, { path: '/' })
              setCookie('site', null, { path: '/' })
            }
          }

          // Sauvegarde des cookies
          setCookie('token', accessToken, { path: '/' }) // Sauvegarder le token
          setCookie('prof', email, { path: '/' }) // Sauvegarder l'email

          // Appel des fonctions après la connexion réussie
          expand()
          fetchWishes()

          // Redirection après l'authentification (vers la page de base)
          window.history.replaceState({}, document.title, '/')
        } else {
          console.error('Aucune adresse email valide trouvée.')
        }
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des infos utilisateur:',
          error
        )
      }
    }

    // Récupérer le token de l'URL
    const accessToken = getAccessTokenFromUrl()

    // Si on a un token, on récupère les données utilisateur
    if (accessToken) {
      fetchUserData(accessToken)
    }
    expand()
    fetchWishes()
  }, [])
  useEffect(() => {
    // Vérifier si les cookies sont bien définis
    if (cookies.token && cookies.prof) {
      // Lancer les fonctions fetchWishes et expand
      fetchWishes()
      expand()
      fetchStatsDatas()
      // Nettoyer l'URL après la connexion réussie
      cleanUrl()
    }
  }, [cookies.token, cookies.prof]) // Le useEffect se déclenche lorsque les cookies changent

  const cleanUrl = () => {
    // Nettoyer l'URL en enlevant les fragments après le '#'
    const cleanUrl = window.location.origin + window.location.pathname
    window.history.replaceState(null, null, cleanUrl)
  }
  const handleOpenStatsDrawer = async () => {
    setOpenStatsDrawer(true)
  }
  const getSiteNameById = (siteId) => {
    for (let site of sites) {
      if (!site.multisite) {
        if (site.id === siteId) {
          return site.nom
        }
      } else {
        const siteIndex = site.id.indexOf(siteId)
        if (siteIndex !== -1) {
          return site.nom[siteIndex]
        }
      }
    }
    return 'Nom non trouvé'
  }
  const fetchStatsDatas = async () => {
    const endpoint =
      cookies.admin === 1
        ? `${apiUrl}/wishlist/site`
        : cookies.admin === 2
        ? `${apiUrl}/wishlist/all`
        : null
    if (endpoint) {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: cookies.site ? JSON.stringify(cookies.site) : null,
      })
      if (response.ok) {
        const data = await response.json()

        // Remplacer l'ID du site par le nom avec getSiteNameByid
        const updatedData = data.map((wish) => {
          return {
            ...wish,
            site: getSiteNameById(wish.site), // Remplacer l'ID par le nom du site
          }
        })

        // Mettre à jour statsDatas avec les nouvelles données
        setStatsDatas(updatedData)
      } else {
        console.error('Failed to sync wish with DB:', response.statusText)
      }
    }
  }
  const seeRetour = (value) => {
    setOpenRetourModal(true)
    setRetourModal(value)
  }
  const closeRetourModal = () => {
    setOpenRetourModal(false)
    setRetourModal(null)
  }
  const generatePdf = () => {
    const element = document.getElementById('pdf-content') // Sélectionne l'élément à convertir en PDF
    const opt = {
      margin: 1,
      filename: 'retour_visite.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    }

    html2pdf().set(opt).from(element).save()
  }
  const addEntries = async () => {
    const endpoint = `${apiUrl}/wishlist/add`

    // Fonction pour générer une phrase aléatoire
    const getRandomPhrase = () => {
      const phrases = [
        'Ceci est un test',
        'Je souhaite une meilleure collaboration',
        'Des ressources supplémentaires seraient utiles',
        'Un espace de travail plus confortable serait idéal',
        'Améliorer la communication interne',
        'Problème avec l’équipement actuel',
        'Souhait d’avoir plus de formations',
        'Besoin de mise à jour de l’outil',
        'Problème de performance détecté',
      ]
      return phrases[Math.floor(Math.random() * phrases.length)]
    }

    // Fonction pour générer un email au format prénom.nom@asihs.org
    const getRandomEmail = () => {
      const firstNames = [
        'alice',
        'bob',
        'charlie',
        'dave',
        'eve',
        'frank',
        'grace',
        'hannah',
        'ian',
        'jane',
      ]
      const lastNames = [
        'smith',
        'johnson',
        'williams',
        'brown',
        'jones',
        'garcia',
        'martinez',
        'lee',
        'white',
        'taylor',
      ]
      const firstName =
        firstNames[Math.floor(Math.random() * firstNames.length)]
      const lastName = lastNames[Math.floor(Math.random() * lastNames.length)]
      return `${firstName}.${lastName}@asihs.org`
    }

    // Liste des ids disponibles
    const siteIds = [1, 5, 6, 7, 8]

    // Génération de 30 à 40 adresses emails aléatoires
    const emailCount = Math.floor(Math.random() * (40 - 30 + 1)) + 30
    const emails = new Set() // Utilisation d'un Set pour éviter les doublons
    while (emails.size < emailCount) {
      emails.add(getRandomEmail())
    }

    // Pour chaque email, on génère entre 1 et 5 entrées
    for (const email of emails) {
      const entryCount = Math.floor(Math.random() * 5) + 1 // Entre 1 et 5 entrées par email
      const usedIds = new Set() // Set pour garder une trace des ids déjà utilisés pour cet email

      for (let i = 0; i < entryCount; i++) {
        let siteId
        do {
          siteId = siteIds[Math.floor(Math.random() * siteIds.length)]
        } while (usedIds.has(siteId)) // S'assurer que le siteId n'a pas encore été utilisé
        usedIds.add(siteId)

        const entry = {
          prof: email,
          site: siteId,
          attentes: getRandomPhrase(),
          pourquoi: getRandomPhrase(),
        }

        try {
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(entry),
          })

          if (response.ok) {
            console.log(`Entrée ajoutée pour ${email} avec succès.`)
          } else {
            console.error(
              `Erreur lors de l'ajout de l'entrée pour ${email}: ${response.statusText}`
            )
          }
        } catch (error) {
          console.error(`Erreur lors de la requête pour ${email}:`, error)
        }
      }
    }
  }
  const generateCSV = (data) => {
    // Ajouter l'en-tête CSV
    let csvContent = 'prof,site,priority,wishId\n'

    // Parcourir les données pour ajouter chaque ligne
    data.forEach((row) => {
      csvContent += `${row.prof},${row.site},${row.priority},${row.wishId}\n`
    })

    // Créer un blob à partir du contenu CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    // Créer un lien de téléchargement
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', 'wish_assignment.csv')

    // Ajouter le lien au DOM, cliquer dessus, et ensuite le retirer
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const assignWishesToSites = () => {
    // Créer une map pour stocker les sites et leur capacité actuelle
    const emailsProf = []
    const siteCapacities = {}
    const siteAssignments = []

    // Initialisation des sites avec leur capacité d'accueil
    sites.forEach((site) => {
      if (site.multisite) {
        site.nom.forEach((siteName, index) => {
          siteCapacities[siteName] = {
            capacity: site.accueil[index],
            wishes: [], // Stocker les souhaits assignés ici
          }
          siteAssignments.push({ site: siteName, profs: [] }) // Initialiser un tableau pour les assignations
        })
      } else {
        siteCapacities[site.nom] = {
          capacity: site.accueil,
          wishes: [], // Stocker les souhaits assignés ici
        }
        siteAssignments.push({ site: site.nom, profs: [] }) // Initialiser un tableau pour les assignations
      }
    })

    const unassignedProfs = [] // Pour stocker les profs non assignés
    const result = [] // Le tableau récapitulatif final

    // Étape 1: Assigner les souhaits de priorité 1 si la capacité le permet
    statsDatas.forEach((wish) => {
      if (!emailsProf.includes(wish.prof)) {
        emailsProf.push(wish.prof)
      }
      const siteName = wish.site // Utilisation du nom du site

      if (wish.priority === 1 && siteCapacities[siteName]) {
        // Si le site a encore de la capacité, on assigne le souhait
        if (
          siteCapacities[siteName].wishes.length <
          siteCapacities[siteName].capacity
        ) {
          siteCapacities[siteName].wishes.push(wish)
          const siteEntry = siteAssignments.find(
            (entry) => entry.site === siteName
          )
          siteEntry.profs.push({
            prof: wish.prof,
            priority: wish.priority,
            wishId: wish.id,
          })
        } else {
          // Si le site est déjà plein, on le traitera plus tard
          unassignedProfs.push(wish)
        }
      }
    })

    // Étape 2: Tenter de réassigner les souhaits non assignés sur leurs autres priorités
    const assignRemainingWishes = (wishes) => {
      let stillUnassigned = []

      wishes.forEach((wish) => {
        const otherPriorities = statsDatas.filter(
          (w) => w.prof === wish.prof && w.priority > wish.priority
        )

        let assigned = false

        // Vérifier les autres priorités dans l'ordre (2, 3, ...)
        for (const nextWish of otherPriorities) {
          const nextSiteName = nextWish.site // Utilisation du nom du site

          if (
            siteCapacities[nextSiteName] &&
            siteCapacities[nextSiteName].wishes.length <
              siteCapacities[nextSiteName].capacity
          ) {
            // Si on trouve un site avec de la capacité, on assigne ce souhait
            siteCapacities[nextSiteName].wishes.push(nextWish)
            const siteEntry = siteAssignments.find(
              (entry) => entry.site === nextSiteName
            )
            siteEntry.profs.push({
              prof: nextWish.prof,
              priority: nextWish.priority,
              wishId: nextWish.id,
            })
            assigned = true
            break // On sort de la boucle une fois assigné
          }
        }

        // Si aucun autre souhait n'a pu être assigné, on garde le prof non assigné
        if (!assigned) {
          stillUnassigned.push(wish)
        }
      })

      return stillUnassigned
    }

    // Réessayer tant qu'il y a des profs non assignés et qu'on a encore des capacités disponibles
    let remainingWishes = unassignedProfs
    while (remainingWishes.length > 0) {
      const previousLength = remainingWishes.length
      remainingWishes = assignRemainingWishes(remainingWishes)

      // Si aucun souhait supplémentaire n'a pu être assigné, on arrête
      if (remainingWishes.length === previousLength) {
        break
      }
    }

    // Récupérer les emails des profs non assignés
    const unassignedEmails = remainingWishes.map((wish) => wish.prof)

    // Ajouter la liste des non assignés comme une entrée séparée dans le même tableau
    siteAssignments.push({
      site: 'Non assignés',
      profs: unassignedEmails.map((prof) => ({ prof })), // Créer un objet par prof non assigné
    })

    // Retourner le tableau unique avec les sites et les non-assignés
    console.log(siteAssignments)
    setSyntheseDatas(siteAssignments)
  }
  const getCapacityBySiteName = (siteName) => {
    // Rechercher dans les sites
    for (const site of sites) {
      if (site.multisite) {
        // Si multisite, on vérifie chaque sous-site
        const index = site.nom.findIndex((name) => name === siteName)
        if (index !== -1) {
          return site.accueil[index] // Récupérer la capacité associée au sous-site
        }
      } else if (site.nom === siteName) {
        // Si ce n'est pas multisite et que le nom correspond
        return site.accueil
      }
    }

    // Si le site n'est pas trouvé, retourner undefined ou une valeur par défaut
    return '?'
  }
  useEffect(() => {
    const uniqueEmails = [...new Set(statsDatas.map((wish) => wish.prof))]
    const emailFilters = uniqueEmails.map((email) => ({
      text: email,
      value: email,
    }))
    setStatsColumns([
      {
        title: 'Adresse email',
        dataIndex: 'prof',
        key: 'prof',
        sorter: (a, b) => a.prof.length - b.prof.length,
        filters: emailFilters,
        filterSearch: true,
        onFilter: (value, record) => record.prof.indexOf(value) === 0,
      },
      {
        title: 'Site souhaité',
        dataIndex: 'site',
        key: 'site',
        showSorterTooltip: {
          target: 'full-header',
        },
        filters:
          cookies.admin === 1
            ? cookies.site.map((s) => ({
                text: getSiteNameById(s),
                value: getSiteNameById(s),
              }))
            : cookies.admin === 2
            ? sites.flatMap((s) =>
                s.multisite
                  ? s.nom.map((n) => ({
                      text: n,
                      value: n,
                    }))
                  : [{ text: s.nom, value: s.nom }]
              )
            : [],
        onFilter: (value, record) => record.site.indexOf(value) === 0,
      },
      {
        title: 'Priorité',
        dataIndex: 'priority',
        key: 'priority',
        sorter: (a, b) => a.priority - b.priority,
      },
      { title: 'Pourquoi ?', dataIndex: 'pourquoi', key: 'pourquoi' },
      { title: 'Attentes ?', dataIndex: 'attentes', key: 'attentes' },
      {
        title: 'Date de visite',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Retour',
        dataIndex: 'retour',
        key: 'retour',
        render: (_, record) => (
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              seeRetour(record.retour)
            }}
          />
        ),
      },
    ])
    fetchStatsDatas()
  }, [openStatsDrawer])
  return !cookies['prof'] || !cookies['token'] ? (
    <div className="App" style={{ width: '100vw', height: '100vh' }}>
      <Flex vertical justify="center" style={{ margin: 20 }}>
        <Title style={{ margin: 0 }} level={1}>
          Journées découvertes
        </Title>
        <Title style={{ margin: 0 }} level={3}>
          à l'école Escale
        </Title>
      </Flex>
      <Flex style={{ margin: 20 }} justify="center">
        <div style={{ width: 500 }}>
          <Title level={4}>OS1 - S1 - A1: Les journées découvertes</Title>
          <p>
            Le but de ces journées découvertes est d'offrir l'opportunité aux
            enseignants et enseignantes de l'école Escale de découvrir la
            réalité pédagogique et clinique d'autres antennes.
          </p>
          <p>
            En tant qu'enseignant•e à l'école Escale, tu es invité•e via cette
            plateforme à indiquer les sites que tu souhaites visiter. Tu peux
            aussi trier tes souhaits par ordre de priorité.
          </p>
          <p>
            Pour acceder à la plateforme, identifie-toi à l'aide de ton adresse
            email <strong>@asihs.org</strong> ou{' '}
            <strong>@ecole-escale.be</strong>.
          </p>
        </div>
      </Flex>
      <Flex justify="center">
        <Button
          type="primary"
          icon={<GoogleOutlined />}
          onClick={handleGoogleLogin}
        >
          Se connecter
        </Button>
      </Flex>
    </div>
  ) : (
    <div
      className="App"
      style={{
        height: '100vh',
        width: '100vw',
        maxWidth: '100vw',
        minWidth: '100vw',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'grid',
        gridTemplateColumns: '70vw 30vw',
        gridTemplateRows: '1fr',
        color: couleurPrimaire,
      }}
    >
      <FloatButton
        shape="square"
        type="primary"
        style={{ insetInlineStart: 24 }}
        badge={{ count: siteWishlist.length, color: couleurTertiaire }}
        icon={<MenuOutlined />}
        onClick={() => setOpenDrawer(true)}
      />
      {cookies.admin > 0 ? (
        <>
          <FloatButton
            shape="square"
            type="primary"
            style={{ insetInlineStart: 90 }}
            icon={<FundViewOutlined />}
            onClick={handleOpenStatsDrawer}
          />
          <Drawer
            title="Les données"
            open={openStatsDrawer}
            onClose={() => setOpenStatsDrawer(false)}
            width={10000}
          >
            <Button onClick={() => assignWishesToSites()}>
              Générer la liste visites
            </Button>
            {syntheseDatas ? (
              <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                <Flex gap={0} style={{ marginBottom: 10, marginTop: 10 }}>
                  {syntheseDatas.map((columns, index) => (
                    <Flex vertical key={index} style={{}}>
                      <div style={{ backgroundColor: '#fafafa', padding: 10 }}>
                        {columns.site}
                      </div>
                      {columns.profs.map((prof, index) =>
                        prof.priority ? (
                          <Popover
                            content={`priorité n°${prof.priority}`}
                            title="Priorité"
                            trigger="click"
                          >
                            <div
                              style={{
                                padding: 10,
                                borderBottom: '1px solid #f0f0f0',
                              }}
                              key={index}
                            >
                              {prof.prof}
                            </div>
                          </Popover>
                        ) : (
                          <div
                            style={{
                              padding: 10,
                              borderBottom: '1px solid #f0f0f0',
                            }}
                            key={index}
                          >
                            {prof.prof}
                          </div>
                        )
                      )}
                      <div style={{ padding: 10 }}>
                        Total: {columns.profs.length} /{' '}
                        {getCapacityBySiteName(columns.site)}
                      </div>
                    </Flex>
                  ))}
                </Flex>
              </div>
            ) : null}
            <Table
              columns={statsColumns}
              dataSource={statsDatas}
              showSorterTooltip={{
                target: 'sorter-icon',
              }}
            />
            <Popconfirm
              onConfirm={addEntries}
              title="Ajouter des données"
              description="Es-tu sûr de vouloir ajouter des données ?"
            >
              <Button danger>
                ajouter aléatoirement des entrées pour tester
              </Button>
            </Popconfirm>
          </Drawer>
          <Modal
            open={openRetourModal}
            title="Retour de la visite"
            onCancel={closeRetourModal}
            width={1000}
            footer={[
              <Button
                icon={<FilePdfOutlined />}
                key="pdf"
                onClick={generatePdf}
              >
                Générer un pdf
              </Button>,
            ]}
          >
            <div
              id="pdf-content"
              dangerouslySetInnerHTML={{ __html: retourModal }}
            />
          </Modal>
        </>
      ) : null}
      <Modal
        open={modalOpen}
        title={drawerSite ? 'Visite du site: ' + drawerSite.nom : null}
        onCancel={handleModalCancel}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Retour
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loadingModal}
            onClick={handleModalOk}
          >
            Enregistrer
          </Button>,
        ]}
      >
        <Flex vertical>
          <Title level={5}>Qu'aimerais-tu voir sur ce site ?</Title>
          <TextArea rows={4} placeholder="..." ref={pourquoiRef} />
        </Flex>

        <Flex vertical>
          <Title level={5}>
            Quelles sont tes attentes si tu visites ce site ?
          </Title>
          <TextArea rows={4} placeholder="..." ref={attentesRef} />
        </Flex>
      </Modal>
      <Drawer
        title="Mes souhaites de visites"
        placement="left"
        onClose={onCloseDrawer}
        open={openDrawer}
        width={450}
        extra={<Button onClick={logOut}>Se déconnecter</Button>}
      >
        <Drawer
          title={editSite ? getSiteName(editSite.site) : null}
          width={900}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
          placement="left"
          destroyOnClose={true}
          extra={
            <Space>
              <Button
                icon={<CloseOutlined />}
                onClick={() => setChildrenDrawer(false)}
              >
                Annuler
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={saveEditSite}
              >
                Enregistrer
              </Button>
            </Space>
          }
        >
          <Title level={3} style={{ marginBottom: 10, marginTop: 10 }}>
            Avant ta visite
          </Title>
          <Flex vertical>
            <Title level={5} style={{ marginTop: 10 }}>
              Qu'aimerais-tu voir sur ce site ?
            </Title>
            <TextArea
              rows={3}
              placeholder="..."
              value={editSite ? editSite.pourquoi : null}
              onChange={(e) => {
                const newPourquoi = e.target.value
                setEditSite((prevEditSite) => ({
                  ...prevEditSite, // Copie les autres propriétés existantes
                  pourquoi: newPourquoi, // Met à jour la propriété `pourquoi`
                }))
              }}
              ref={pourquoiRefDrawer}
            />
          </Flex>
          <Flex vertical>
            <Title level={5} style={{ marginTop: 10 }}>
              Quelles sont tes attentes si tu visites ce site ?
            </Title>
            <TextArea
              rows={3}
              placeholder="..."
              value={editSite ? editSite.attentes : null}
              onChange={(e) => {
                const newAttentes = e.target.value
                setEditSite((prevEditSite) => ({
                  ...prevEditSite, // Copie les autres propriétés existantes
                  attentes: newAttentes, // Met à jour la propriété `pourquoi`
                }))
              }}
              ref={attentesRefDrawer}
            />
          </Flex>
          <Title level={3} style={{ marginBottom: 10, marginTop: 30 }}>
            Après ta visite
          </Title>
          <Flex vertical>
            <Title level={5} style={{ marginTop: 10 }}>
              Quelle est la date de ta visite ?
            </Title>
            <ConfigProvider locale={locale}>
              <DatePicker
                defaultValue={
                  editSite
                    ? editSite.date
                      ? dayjs(editSite.date, 'DD/MM/YYYY')
                      : dayjs()
                    : null
                }
                format={'DD/MM/YYYY'}
                onChange={(e) => {
                  const date = e.format('DD/MM/YYYY')
                  setEditSite((prevEditSite) => ({
                    ...prevEditSite, // Copie les autres propriétés existantes
                    date: date, // Met à jour la propriété `pourquoi`
                  }))
                }}
              />
            </ConfigProvider>
          </Flex>
          <Flex vertical>
            <Title level={5} style={{ marginTop: 10 }}>
              Quel retour peux-tu donner au site qui t'a accueilli ?
            </Title>

            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: {
                  items: [
                    'heading', // Titres
                    'fontSize', // Taille de texte
                    'bold', // Gras
                    'italic', // Italique
                    'underline', // Souligné
                    'strikethrough', // Barré
                    'highlight', // Surligné
                    '|',
                    'bulletedList', // Liste non ordonnée (ul)
                    'numberedList', // Liste ordonnée (ol)
                    '|',
                    'alignment', // Alignement de texte
                    'link', // Liens
                    'imageUpload', // Image
                    'undo', // Annuler
                    'redo', // Répéter
                  ],
                },
                plugins: [
                  Bold,
                  Italic,
                  Underline,
                  Strikethrough,
                  Font,
                  Highlight,
                  Paragraph,
                  List,
                  Alignment,
                  Link,
                  Image,
                ],
                fontSize: {
                  options: ['tiny', 'small', 'default', 'big', 'huge'],
                },
                alignment: {
                  options: ['left', 'center', 'right', 'justify'],
                },
                highlight: {
                  options: [
                    {
                      model: 'yellowMarker',
                      class: 'marker-yellow',
                      title: 'Yellow marker',
                      color: 'var(--ck-highlight-marker-yellow)',
                      type: 'marker',
                    },
                    {
                      model: 'greenMarker',
                      class: 'marker-green',
                      title: 'Green marker',
                      color: 'var(--ck-highlight-marker-green)',
                      type: 'marker',
                    },
                    {
                      model: 'pinkMarker',
                      class: 'marker-pink',
                      title: 'Pink marker',
                      color: 'var(--ck-highlight-marker-pink)',
                      type: 'marker',
                    },
                  ],
                },
                initialData: editSite ? editSite.retour : null,
              }}
              onChange={(e, editor) => {
                const retour = editor.data.get()

                setEditSite((prevEditSite) => ({
                  ...prevEditSite, // Copie les autres propriétés existantes
                  retour: retour, // Met à jour la propriété `pourquoi`
                }))
              }}
            />
          </Flex>
        </Drawer>
        <Draglist
          liste={wishlist}
          setListe={setWishlist}
          ids={siteWishlist}
          setIds={setSiteWishlist}
          sites={sites}
          setChildrenDrawer={setChildrenDrawer}
          childrenDrawer={childrenDrawer}
          editSite={editSite}
          setEditSite={setEditSite}
          setIsInitialMount={setIsInitialMount}
          setWishlist={setWishlist}
        />
      </Drawer>
      <Flex vertical style={{ height: '100vh', position: 'relative' }}>
        <Flex vertical justify="center" style={{ position: 'relative' }}>
          <Title level={1} style={{ color: couleurSecondaire }}>
            Journées découvertes
          </Title>
        </Flex>
        <Flex
          justify="end"
          style={{ position: 'absolute', bottom: 48, right: 20 }}
        >
          <Radio.Group
            defaultValue={true}
            buttonStyle="solid"
            onChange={() => setAffichageMap(!affichageMap)}
          >
            <Radio.Button value={true}>Carte</Radio.Button>
            <Radio.Button value={false}>Liste</Radio.Button>
          </Radio.Group>
        </Flex>
        {affichageMap ? (
          <svg
            className="map"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-10 -10 3200 2200"
            style={{
              maxWidth: '70vw',
              height: '100%',
              paddingTop: 40,
              paddingLeft: 40,
            }}
          >
            <polygon
              style={{ stroke: couleurPrimaire }}
              onMouseEnter={mapOpen === 'bxl' ? expand : null}
              ref={walRef}
              points="0.69 102.56 929.99 164.58 1496.17 36.53 1982.02 119.66 2381.46 0.52 2782.59 126.56 3096.69 722.09 2691.75 994.71 2472.49 1499.01 2553.52 1918.14 2210.41 1975.16 1596.21 1479 1572.2 1002.85 1253.1 1317.95 944 1298.94 933.99 874.8 100.72 426.66 0.69 102.56"
            />
            <polygon
              style={{ stroke: couleurPrimaire }}
              onMouseEnter={mapOpen === 'wal' ? expand : null}
              ref={bxlRef}
              points="428.48 1517.32 452.09 1511.48 455.93 1486.46 449.08 1475.48 465.15 1457.31 461.24 1444.35 498.71 1426.05 530 1433.07 549.05 1409.02 578.02 1434.74 569.86 1472.07 603.52 1493.74 613.52 1534.45 588.84 1547.83 616.82 1568.92 536.34 1609.02 488.15 1594.03 474.36 1545.59 428.48 1517.32"
            />
            {sites.map((site, key) =>
              site.nom !== '' ? (
                <Popover
                  key={key}
                  content={
                    Array.isArray(site.nom) ? (
                      <Flex vertical>
                        {site.nom.map((s, index) => (
                          <Button
                            type="text"
                            key={s}
                            onClick={() => {
                              handleMouseOver(site, index, key)
                              setSegmentedIndex(index)
                            }}
                          >
                            {s}
                          </Button>
                        ))}
                      </Flex>
                    ) : (
                      <p>{site.nom}</p>
                    )
                  }
                >
                  <circle
                    className={site.lieu + 'Point'}
                    cx={site.cx}
                    cy={site.cy}
                    r="15"
                    style={{
                      opacity: 0,
                      cursor: mapOpen === site.lieu ? 'pointer' : null,
                      fill: siteKey === key ? couleurPrimaire : '#ffffff',
                      stroke: couleurPrimaire,
                    }}
                    onClick={
                      mapOpen === site.lieu
                        ? () => handleMouseOver(site, 0, key)
                        : null
                    }
                  />
                </Popover>
              ) : null
            )}
          </svg>
        ) : (
          <Space direction="vertical" style={{ overflowY: 'scroll' }}>
            {sites.map((site, index) =>
              site.multisite ? (
                site.nom.map((nom, siteIndex) => (
                  <Button
                    type="text"
                    key={`${index}_${siteIndex}`}
                    onClick={() => {
                      handleMouseOver(site, siteIndex, index)
                      setSegmentedIndex(siteIndex)
                    }}
                  >
                    {nom}
                  </Button>
                ))
              ) : (
                <Button
                  type="text"
                  key={index}
                  onClick={() => {
                    setDrawerSite(site)
                    setSiteKey(index)
                  }}
                >
                  {site.nom}
                </Button>
              )
            )}
          </Space>
        )}
      </Flex>
      <div
        className="Drawer"
        style={{
          paddingTop: 10,
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          maxWidth: '30vw',
          overflowY: 'scroll',
          marginBottom: 10,
        }}
      >
        {drawerSite ? (
          <Flex gap={10} style={{ width: '100%', maxWidth: '30vw' }}>
            {drawerSite ? (
              <Flex
                vertical
                style={{ width: '100%', maxWidth: '30vw', padding: 10 }}
              >
                {drawerSite.multisite ? (
                  <Segmented
                    options={drawerSite.noms.map((nom, index) => ({
                      label: nom,
                      value: index,
                    }))}
                    block
                    value={segmentedIndex ? segmentedIndex : 0}
                    onChange={(value) => {
                      setDrawerSite(getSite(OGSite, value))
                      setSiteId(OGSite.id[value])
                      setSegmentedIndex(value)
                    }}
                  />
                ) : (
                  <Title
                    level={2}
                    style={{ color: couleurSecondaire, margin: 0 }}
                  >
                    {drawerSite.nom}
                  </Title>
                )}
                <Flex vertical style={{ marginTop: 20 }}>
                  <span>{`${drawerSite.adresse[0]} n°${drawerSite.adresse[1]}`}</span>
                  <span>{`${drawerSite.adresse[2]}, ${drawerSite.adresse[3]}`}</span>

                  {drawerSite.telephone.map((num, index) => (
                    <span key={index}>
                      <PhoneOutlined style={{ marginRight: 5 }} />
                      {formatPhoneNumber(num)}
                    </span>
                  ))}

                  <a
                    href={drawerSite.lien}
                    target="_blank"
                    style={{ color: couleurPrimaire }}
                  >
                    <LinkOutlined style={{ marginRight: 5 }} />
                    Tryptique
                  </a>
                </Flex>
                <Flex vertical>
                  <Divider
                    orientation="center"
                    style={{ color: couleurSecondaire, fontSize: '1.2em' }}
                  >
                    Description du site
                  </Divider>
                  <div style={{ textAlign: 'left' }}>
                    {drawerSite.description}
                  </div>
                </Flex>
                <Flex vertical>
                  <Divider
                    orientation="center"
                    style={{ color: couleurSecondaire, fontSize: '1.2em' }}
                  >
                    Les activités
                  </Divider>
                  <Flex vertical style={{ width: 'auto', display: 'inline' }}>
                    {drawerSite.activites.map((acti, index) => (
                      <p style={{ margin: 0 }} key={index}>
                        <Popover
                          content={acti.contenu}
                          title={acti.titre}
                          style={{ cursor: 'pointer' }}
                          className="help"
                        >
                          {acti.titre}
                        </Popover>
                      </p>
                    ))}
                  </Flex>
                </Flex>
                <Flex vertical style={{ position: 'relative' }}>
                  <Divider
                    orientation="center "
                    style={{ color: couleurSecondaire, fontSize: '1.2em' }}
                  >
                    Les horaires
                  </Divider>
                  <Flex vertical align="center">
                    <div
                      style={{
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                        gridTemplateRows: 'auto',
                        maxWidth: '30vw',
                        gap: 5,
                      }}
                    >
                      <span>Lundi</span>
                      <span>Mardi</span>
                      <span>Mercredi</span>
                      <span>Jeudi</span>
                      <span>Vendredi</span>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                        gridTemplateRows: 'auto',
                        maxWidth: '30vw',
                        gap: 7,
                        marginTop: 10,
                      }}
                    >
                      <div style={{ position: 'relative' }}>
                        {drawerSite.horaires.lundi.map((jour, jourIndex) => (
                          <Tooltip
                            title={jour.horaire}
                            placement="left"
                            key={jourIndex}
                          >
                            <Tooltip title={jour.type}>
                              <div
                                style={{
                                  position: 'relative',
                                  marginBottom: 3,
                                  height: jour.duree * 30,
                                  width: '100%',
                                  backgroundColor: jour.color,
                                  borderRadius: 3,
                                }}
                              ></div>
                            </Tooltip>
                          </Tooltip>
                        ))}
                      </div>
                      <div style={{ position: 'relative' }}>
                        {drawerSite.horaires.mardi.map((jour, jourIndex) => (
                          <Tooltip
                            title={jour.horaire}
                            placement="left"
                            key={jourIndex}
                          >
                            <Tooltip title={jour.type}>
                              <div
                                style={{
                                  position: 'relative',
                                  marginBottom: 3,
                                  height: jour.duree * 30,
                                  width: '100%',
                                  backgroundColor: jour.color,
                                  borderRadius: 3,
                                }}
                              ></div>
                            </Tooltip>
                          </Tooltip>
                        ))}
                      </div>
                      <div style={{ position: 'relative' }}>
                        {drawerSite.horaires.mercredi.map((jour, jourIndex) => (
                          <Tooltip
                            title={jour.horaire}
                            placement="left"
                            key={jourIndex}
                          >
                            <Tooltip title={jour.type}>
                              <div
                                style={{
                                  position: 'relative',
                                  marginBottom: 3,
                                  height: jour.duree * 30,
                                  width: '100%',
                                  backgroundColor: jour.color,
                                  borderRadius: 3,
                                }}
                              ></div>
                            </Tooltip>
                          </Tooltip>
                        ))}
                      </div>
                      <div style={{ position: 'relative' }}>
                        {drawerSite.horaires.jeudi.map((jour, jourIndex) => (
                          <Tooltip
                            title={jour.horaire}
                            placement="left"
                            key={jourIndex}
                          >
                            <Tooltip title={jour.type}>
                              <div
                                style={{
                                  position: 'relative',
                                  marginBottom: 3,
                                  height: jour.duree * 30,
                                  width: '100%',
                                  backgroundColor: jour.color,
                                  borderRadius: 3,
                                }}
                              ></div>
                            </Tooltip>
                          </Tooltip>
                        ))}
                      </div>
                      <div style={{ position: 'relative' }}>
                        {drawerSite.horaires.vendredi.map((jour, jourIndex) => (
                          <Tooltip
                            title={jour.horaire}
                            placement="left"
                            key={jourIndex}
                          >
                            <Tooltip title={jour.type}>
                              <div
                                style={{
                                  position: 'relative',
                                  marginBottom: 3,
                                  height: jour.duree * 30,
                                  width: '100%',
                                  backgroundColor: jour.color,
                                  borderRadius: 3,
                                }}
                              ></div>
                            </Tooltip>
                          </Tooltip>
                        ))}
                      </div>
                    </div>
                  </Flex>
                </Flex>
                <Flex vertical>
                  <Divider
                    orientation="center"
                    style={{ color: couleurSecondaire, fontSize: '1.2em' }}
                  >
                    Personne de contact
                  </Divider>
                  <span>{drawerSite.contact[0]}</span>
                  <span>
                    <MailOutlined style={{ marginRight: 5 }} />
                    {drawerSite.contact[1]}
                  </span>
                  <span>
                    <PhoneOutlined style={{ marginRight: 5 }} />
                    {formatPhoneNumber(drawerSite.contact[2])}
                  </span>
                </Flex>
                <Flex justify="end" style={{ marginTop: 10 }}>
                  <Button
                    type="primary"
                    disabled={siteWishlist.includes(drawerSite.id)}
                    onClick={() => openingModal(drawerSite.id)}
                  >
                    {siteWishlist.includes(drawerSite.id)
                      ? "J'ai déjà choisi ce site"
                      : 'Je souhaite visiter ce site'}
                  </Button>
                </Flex>
              </Flex>
            ) : null}
          </Flex>
        ) : (
          <Flex>
            <Title level={3} style={{ color: couleurSecondaire }}>
              Choisi un site
            </Title>
          </Flex>
        )}
      </div>
    </div>
  )
}

export default App
